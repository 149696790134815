







































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getUseCoinLevelInfo } from '@/pages/videoGame/api'
import { dateFormat } from '@/common/utils'
@Component
export default class extends Vue {
  list: any = []
  middleList: any = []
  endList: any = []
  showMid = false
  showEnd = false
  startTime = ''
  endTime = ''
  async getData() {
    //
    const { data } = await getUseCoinLevelInfo({ activityType: 2 })
    this.startTime = dateFormat('yyyy.MM.dd', new Date(data.startTimestamp))
    this.endTime = dateFormat('yyyy.MM.dd', new Date(data.endTimestamp))

    data.levels.forEach((item: any) => {
      if (item.levelId <= 15) {
        this.list.push(item)
      } else if (item.levelId <= 25) {
        this.middleList.push(item)
      } else {
        this.endList.push(item)
      }
    })
  }
  mounted() {
    this.getData()
  }
}
