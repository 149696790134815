var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"challenge-page"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"header-bg",attrs:{"src":require("_videoGame/assets/levelchallenge/header.png")}}),_c('div',{staticClass:"time"},[_vm._v("活动时间:"+_vm._s(_vm.startTime)+"-"+_vm._s(_vm.endTime))])]),_c('img',{staticClass:"title",attrs:{"src":require("_videoGame/assets/levelchallenge/first_title.png")}}),_c('div',{staticClass:"first-content"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"level-item"},[_c('img',{staticClass:"reward",attrs:{"src":require("_videoGame/assets/levelchallenge/reward.png")}}),_c('div',{staticClass:"level-mid"},[_c('div',{attrs:{"clsss":"level-title"}},[_vm._v(" 消耗"+_vm._s(item.useCoin)+"币返"+_vm._s(item.awardCoin)+"金币 ")]),_c('div',{staticClass:"propgress-box mt-8",class:{
            'progress-finished': item.receive == 1
          }},[(item.receive != 1)?_c('div',{staticClass:"progress",style:({ width: (item.userUseCoin / item.useCoin) * 100 + '%' })}):_vm._e(),_c('div',{staticClass:"progress-text"},[_vm._v(" "+_vm._s(item.receive ? item.useCoin : item.userUseCoin)+"/"+_vm._s(item.useCoin)+" ")])])]),_c('div',{staticClass:"level-status"},[(item.receive == 0)?_c('img',{attrs:{"src":require("_videoGame/assets/levelchallenge/unfinish.png")}}):_c('div',[_vm._v("已领取")])])])}),(!_vm.showMid)?_c('img',{staticClass:"look",attrs:{"src":require("_videoGame/assets/levelchallenge/look.png")},on:{"click":function($event){_vm.showMid = true}}}):_vm._e()],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMid),expression:"showMid"}],staticClass:"content-2"},[_c('img',{staticClass:"title",attrs:{"src":require("_videoGame/assets/levelchallenge/mid_title.png")}}),_c('div',{staticClass:"mid-content first-content"},[_vm._l((_vm.middleList),function(item,index){return _c('div',{key:index,staticClass:"level-item"},[_c('img',{staticClass:"reward",attrs:{"src":require("_videoGame/assets/levelchallenge/reward.png")}}),_c('div',{staticClass:"level-mid"},[_c('div',{attrs:{"clsss":"level-title"}},[_vm._v(" 消耗"+_vm._s(item.useCoin)+"币返"+_vm._s(item.awardCoin)+"金币 ")]),_c('div',{staticClass:"propgress-box",class:{
              'progress-finished': item.receive == 1
            }},[(item.receive != 1)?_c('div',{staticClass:"progress",style:({
                width: (item.userUseCoin / item.useCoin) * 100 + '%'
              })}):_vm._e(),_c('div',{staticClass:"progress-text"},[_vm._v(" "+_vm._s(item.receive ? item.useCoin : item.userUseCoin)+"/"+_vm._s(item.useCoin)+" ")])])]),_c('div',{staticClass:"level-status"},[(item.receive == 0)?_c('img',{attrs:{"src":require("_videoGame/assets/levelchallenge/unfinish.png")}}):_c('div',[_vm._v("已领取")])])])}),(!_vm.showEnd)?_c('img',{staticClass:"look",attrs:{"src":require("_videoGame/assets/levelchallenge/look.png")},on:{"click":function($event){_vm.showEnd = true}}}):_vm._e()],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEnd),expression:"showEnd"}],staticClass:"content-3"},[_c('img',{staticClass:"title",attrs:{"src":require("_videoGame/assets/levelchallenge/end_title.png")}}),_c('div',{staticClass:"mid-content first-content"},_vm._l((_vm.endList),function(item,index){return _c('div',{key:index,staticClass:"level-item"},[(index == _vm.endList.length - 1)?_c('img',{staticClass:"last-icon",attrs:{"src":require("_videoGame/assets/levelchallenge/zhong ji chong ci@2x.png")}}):_vm._e(),_c('img',{staticClass:"reward",attrs:{"src":require("_videoGame/assets/levelchallenge/reward.png")}}),_c('div',{staticClass:"level-mid"},[_c('div',{attrs:{"clsss":"level-title"}},[_vm._v(" 消耗"+_vm._s(item.useCoin)+"币返"+_vm._s(item.awardCoin)+"金币 ")]),_c('div',{staticClass:"propgress-box",class:{
              'progress-finished': item.receive == 1
            }},[(item.receive != 1)?_c('div',{staticClass:"progress",style:({
                width: (item.userUseCoin / item.useCoin) * 100 + '%'
              })}):_vm._e(),_c('div',{staticClass:"progress-text"},[_vm._v(" "+_vm._s(item.receive ? item.useCoin : item.userUseCoin)+"/"+_vm._s(item.useCoin)+" ")])])]),_c('div',{staticClass:"level-status"},[(item.receive == 0)?_c('img',{attrs:{"src":require("_videoGame/assets/levelchallenge/unfinish.png")}}):_c('div',[_vm._v("已领取")])])])}),0)]),_c('img',{staticClass:"rule",attrs:{"src":require("_videoGame/assets/levelchallenge/rule.png")}})])}
var staticRenderFns = []

export { render, staticRenderFns }